import { useTranslation } from "react-i18next";

const LanguageSwitch = (props) => {
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  const onClickLanguageChange = (e) => {
    const language = e.target.value;
    i18n.changeLanguage(language);
    props.passLanguageChange(language);
  };

  return (
    !window.location.href.includes("safe-space.io") && (
      <div className="language-switch">
        <select value={activeLocale} className="custom-select" onChange={onClickLanguageChange}>
          <option value="en">EN</option>
          <option value="de">DE</option>
        </select>
      </div>
    )
  );
};

export default LanguageSwitch;
