// with all at max? tfrid=t97n564oin4buhkk64t97n5qj3gnbn7h

export interface dataVariable {
  key: string;
  number: number;
}

interface configArea {
  area: string;
  copyId: string;
  calculations: {
    focus: string;
    copyId: string;
    variables: string[];
    unsureRange: number[];
    maxPossible: number;
    minPossible: number;
  }[];
}

interface results {
  area: string;
  copyId: string;
  calculations: {
    focus: string;
    copyId: string;
    result: string;
    avg: number; // sum of (normalised score (total / number of questions) for each value)
    maxPossible: number; // max possible score for this section (static, set by config)
    minPossible: number; // min possible score for this section (static, set by config)
  }[];
}

const CONFIG: configArea[] = [
  {
    area: "Ich selbst", // Label or header for this section
    copyId: "1", // intro text is `/public/copy/${copyId}_intro.md`,
    calculations: [
      {
        focus: "Insgesamt", // Label or header for this sub-section
        copyId: "1_1", // appended with '_disclose.md", "_dont.md", or "_unsure.md" to give the text for the relevant result
        variables: ["sym", "acc", "stig", "pers"], // variables from Typeform to add up for this result's calculation
        unsureRange: [1.62, 2], // below left = "dont", above right = "disclose", in between = "unsure"
        minPossible: 1,
        maxPossible: 3,
      },
      {
        focus: "Gesundheitliche Einschränkungen",
        copyId: "1_2",
        variables: ["sym"],
        unsureRange: [1.75, 2],
        minPossible: 1,
        maxPossible: 3,
      },
      {
        focus: "Anpassungen",
        copyId: "1_3",
        variables: ["acc"],
        unsureRange: [2, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Selbststigma",
        copyId: "1_4",
        variables: ["stig"],
        unsureRange: [1.6, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Persönlichkeit / Bedürfnisse",
        copyId: "1_5",
        variables: ["pers"],
        unsureRange: [1.6, 2],
        maxPossible: 3,
        minPossible: 1,
      },
    ],
  },
  {
    area: "Mein Team",
    copyId: "2",
    calculations: [
      {
        focus: "Insgesamt",
        copyId: "2_1",
        variables: ["psysa", "sosp", "incl", "stig_o"],
        unsureRange: [1.75, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Psychologische Sicherheit",
        copyId: "2_2",
        variables: ["psysa", "pres"],
        unsureRange: [1.75, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Soziale Unterstützung",
        copyId: "2_3",
        variables: ["sosp"],
        unsureRange: [1.6, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Inklusives Klima",
        copyId: "2_4",
        variables: ["incl", "stig_o"],
        unsureRange: [1.75, 2],
        maxPossible: 3,
        minPossible: 1,
      },
    ],
  },
  {
    area: "Meine Führungskraft",
    copyId: "3",
    calculations: [
      {
        focus: "Insgesamt",
        copyId: "3_1",
        variables: ["lmx", "emp"],
        unsureRange: [1.5, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Beziehung",
        copyId: "3_2",
        variables: ["lmx"],
        unsureRange: [1.6, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Empathie",
        copyId: "3_3",
        variables: ["emp"],
        unsureRange: [1.5, 2],
        maxPossible: 3,
        minPossible: 1,
      },
    ],
  },
  {
    area: "Meine Organisation",
    copyId: "4",
    calculations: [
      {
        focus: "Insgesamt",
        copyId: "4_1",
        variables: ["orgac", "chan", "orgst"],
        unsureRange: [1.5, 2.5],
        // unkown:
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Unterstützungsangebote",
        copyId: "4_2",
        variables: ["orgac"],
        unsureRange: [1.5, 2.5],
        // unkown:
        maxPossible: 1,
        minPossible: 3,
      },
      {
        focus: "Chancengleichheit",
        copyId: "4_3",
        variables: ["chan"],
        unsureRange: [1.6, 2],
        maxPossible: 3,
        minPossible: 1,
      },
      {
        focus: "Stigma in der Organisation",
        copyId: "4_4",
        variables: ["orgst"],
        unsureRange: [1.5, 2],
        maxPossible: 3,
        minPossible: 1,
      },
    ],
  },
];

/**
 * Finds average value from array of {key, value} pairs, (total divided by number of questions)
 * @param {dataVariable[]} data
 * @param {string} keyToFind
 * @returns {number}
 */
function findKeyValue(data: dataVariable[], keyToFind: string): number {
  const variableData = data.find(({ key }) => key === keyToFind);
  if (variableData) {
    return variableData.number;
  } else console.log(`${keyToFind} not found!`);
}

/**
 * returns CONFIG merged with actual results from the survey
 * @param {dataVariable[]} data
 * @returns {results[]}
 */
export function calculateMyself(data: dataVariable[]): results[] {
  return CONFIG.map((area) => ({
    ...area,
    calculations: area.calculations.map((calculation) => {
      const { focus, copyId, unsureRange, minPossible, maxPossible } = calculation;

      const sum = calculation.variables.reduce((acc, variable) => {
        return acc + findKeyValue(data, variable);
      }, 0);

      const divisor = calculation.variables.reduce((acc, variable) => {
        return acc + QUESTIONS_PER_KEY[variable];
      }, 0);

      const avg = sum / divisor;

      let result: string;

      if (avg > unsureRange[1]) {
        result = "disclose";
      } else if (avg < unsureRange[0]) {
        result = "dont";
      } else {
        result = "unsure";
      }

      return {
        focus,
        copyId,
        result,
        avg,
        minPossible,
        maxPossible,
      };
    }),
  }));
}

/**
 * Default set of keys to get the average from
 * @type {string[]}
 */
const KEYS_FOR_FINAL_AVERAGE: string[] = ["sym", "acc", "stig", "pers"];
const QUESTIONS_PER_KEY = {
  acc: 1,
  emp: 2,
  incl: 3,
  lmx: 3,
  orgac: 1,
  pers: 3,
  pres: 1,
  psysa: 3,
  sosp: 3,
  stig: 3,
  stig_o: 1,
  sym: 3,
  chan: 3,
  orgst: 2,
};

/**
 * For the variables given, work out the average.
 * @param {dataVariable[]} data
 * @param {string[]} keys
 * @returns {number}
 */
export function calculateAverageFromKeys(
  data: dataVariable[],
  keys: string[] = KEYS_FOR_FINAL_AVERAGE,
): number {
  return (
    keys.reduce((acc, key) => {
      return acc + findKeyValue(data, key);
    }, 0) / KEYS_FOR_FINAL_AVERAGE.length
  );
}
