import "./scss/style.scss";
import About from "./pages/About";
import AdminApp from "./pages/AdminApp";
import Companies from "./pages/Companies";
import Datenschutz from "./pages/Datenschutz";
import Employees from "./pages/Employees";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Partner from "./pages/Partner";
import Start from "./pages/Start";
import Survey from "./pages/Survey";
import i18n from "./i18n";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function App() {
  const [language] = useState(i18n.language);
  const navigate = useNavigate(); // useNavigate hook
  const location = useLocation();

  useEffect(() => {
    // Check if the current pathname contains a language code
    const hasLanguageCode = location.pathname.slice(1).length > 1;

    // If no language code is present, redirect to default language
    if (!hasLanguageCode) {
      navigate(`/${language}`); // navigate programmatically
    }
  }, [location.pathname, language, navigate]);

  return (
    <Routes>
      <Route path=":language" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="start" element={<Start />} />
        <Route path="survey" element={<Survey />} />
        <Route path="admin" element={<AdminApp />} />
        <Route path="employees" element={<Employees />} />
        <Route path="companies" element={<Companies />} />
        <Route path="about" element={<About />} />
        <Route path="datenschutz" element={<Datenschutz />} />
        <Route path="partner" element={<Partner />} />
      </Route>
    </Routes>
  );
}
