import { NavLink } from "react-router-dom";
import logo from "../img/ebgb_d_cymk_neg_hoch.png";

const Footer = () => {
  return (
    <>
      <footer className="Footer">
        <div className="FooterTop">
          <p>
            <b>EnableMe Foundation</b>
            <br />
            Oberer Graben 3<br />
            9000 St. Gallen
          </p>
          <img src={logo} alt="logo" />
        </div>
        <div className="FooterBottom">
          <div>
            <NavLink href="mailto: kontakt@safe-space.io">kontakt@safe-space.io</NavLink>
            <NavLink href="tel:+41 71 911 49 49">+41 71 911 49 49</NavLink>
            <NavLink href="/datenschutz">Datenschutz</NavLink>
          </div>
          <div></div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
